@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    color-scheme: light dark;
  }

  html {
    scroll-behavior: smooth;
    font-family: 'Roboto', sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
  }
  
  body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    @apply text-gray-900 dark:text-gray-100;
    letter-spacing: -0.01em;
    @apply bg-gradient-to-br from-gray-50 to-slate-50 dark:from-gray-900 dark:to-slate-900;
    @apply transition-colors duration-300;
  }
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }

  h1, h2, h3, h4, h5, h6 {
    letter-spacing: -0.02em;
  }
}

@layer components {
  .gradient-text {
    @apply bg-gradient-to-br from-gray-800 to-gray-900 dark:from-gray-100 dark:to-gray-200 bg-clip-text text-transparent tracking-tight;
  }

  .container-responsive {
    @apply max-w-7xl mx-auto px-4 sm:px-6 lg:px-8;
  }

  .section-spacing {
    @apply py-12 sm:py-16 md:py-20 lg:py-24;
  }

  .content-spacing {
    @apply space-y-6 sm:space-y-8 md:space-y-10;
  }

  .h1-responsive {
    @apply text-4xl sm:text-5xl lg:text-6xl font-bold tracking-tight;
  }

  .h2-responsive {
    @apply text-3xl sm:text-4xl lg:text-5xl font-bold tracking-tight;
  }

  .h3-responsive {
    @apply text-2xl sm:text-3xl lg:text-4xl font-bold tracking-tight;
  }

  .text-responsive {
    @apply text-base sm:text-lg lg:text-xl leading-relaxed;
  }

  /* Card Components */
  .card {
    @apply bg-white/90 dark:bg-gray-800/90 
           border border-gray-100 dark:border-gray-700 
           shadow-sm hover:shadow-lg 
           transition-all duration-300;
  }

  /* Navigation */
  .nav-bg {
    @apply bg-white/80 dark:bg-gray-900/80
           border-white/20 dark:border-gray-800/20
           backdrop-blur-xl;
  }

  /* Skill Cards */
  .skill-card {
    @apply bg-white/90 dark:bg-gray-800/90
           border border-gray-100 dark:border-gray-700
           hover:border-sky-100 dark:hover:border-sky-800
           transition-all duration-300;
  }

  /* Hero Section */
  .hero-gradient {
    @apply bg-gradient-to-br from-sky-50/80 via-blue-50/80 to-indigo-50/80
           dark:from-sky-900/20 dark:via-blue-900/20 dark:to-indigo-900/20;
  }

  /* Social Buttons */
  .social-button {
    @apply bg-white/80 dark:bg-gray-800/80
           hover:bg-white dark:hover:bg-gray-700
           text-gray-600 dark:text-gray-400
           hover:text-gray-900 dark:hover:text-gray-200
           transition-all duration-300;
  }
}

@layer utilities {
  /* Improve tap targets on mobile */
  @media (max-width: 640px) {
    .mobile-tap-target {
      @apply min-h-[44px] min-w-[44px];
    }
  }

  /* Focus styles */
  .focus-visible {
    @apply outline-none ring-2 ring-sky-500 dark:ring-sky-400 
           ring-offset-2 ring-offset-white dark:ring-offset-gray-900;
  }

  /* Animation utilities */
  .animate-fade-in {
    animation: fadeIn 0.5s ease-out forwards;
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  /* Gradient Overlays */
  .gradient-overlay-light {
    @apply bg-gradient-to-t from-black/30 via-transparent to-transparent;
  }

  .gradient-overlay-dark {
    @apply bg-gradient-to-t from-black/50 via-transparent to-transparent;
  }
}

/* Print styles */
@media print {
  .no-print {
    display: none;
  }

  body {
    @apply bg-white text-black;
  }

  a {
    text-decoration: none;
  }

  .print-break-inside-avoid {
    break-inside: avoid;
  }
}

/* Reduced motion */
@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

/* Backdrop blur support */
@supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
  .backdrop-blur {
    -webkit-backdrop-filter: blur(8px);
    backdrop-filter: blur(8px);
  }
}

/* Custom scrollbar */
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  @apply bg-gray-100 dark:bg-gray-800;
}

::-webkit-scrollbar-thumb {
  @apply bg-gray-300 dark:bg-gray-600 
         rounded-full
         hover:bg-gray-400 dark:hover:bg-gray-500
         transition-colors duration-300;
}

/* Selection color */
::selection {
  @apply bg-sky-200/60 dark:bg-sky-800/60 
         text-gray-900 dark:text-gray-100;
}